<template>
  <div class="bulkImport">
    <div v-if="showErrorMap == 0">
      <div>
        <el-form label-position="left" label-width="0px" :rules="rules" :model="fileFrom" ref="fileFroms">
          <el-form-item label="" prop="amountId">
            <div class="line-el-select">
              <el-select class="el-select-width" v-model="fileFrom.amountId" size="small" @change="changeValues"
                         placeholder="请选择服务商">
                <el-option v-for="(item, index) in amountList" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <div class="flex-nowrap-space-between">
            <div class="el-select-width-other">
              <el-form-item label="" prop="interfaceId">
                <div class="line-el-select">
                  <el-select class="el-select-width" v-model="fileFrom.interfaceId" size="small"
                             placeholder="请选择子账户" filterable>
                    <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>
            <div class="el-select-width-other">
              <!--              <el-form-item label="" prop="industryId">-->
              <!--                <div class="line-el-select">-->

              <!--                  <el-select filterable remote placeholder="请选择行业" v-model="fileFrom.industryId"   size="small" clearable>-->
              <!--                    <el-option v-for="item in label " :key="item.id" :label="item.name" :value="item.id">-->
              <!--                    </el-option>-->
              <!--                  </el-select>-->

              <!--                  &lt;!&ndash; -->
              <!--                  <el-select class="el-select-width" v-model="fileFrom.businessType" size="small" placeholder="请选择行业">-->
              <!--                    <el-option v-for="(item, index) in ['非金融', '房产', '家装']" :key="index" :label="item" :value="index">-->
              <!--                    </el-option>-->
              <!--                  </el-select> &ndash;&gt;-->
              <!--                </div>-->
              <!--              </el-form-item>-->
              <el-form-item label="" prop="industryId">
                <el-cascader :props="industryTreeProps" filterable placeholder="请选择行业" clearable
                             style="width: 100%"
                             v-model="industryValue"
                             :options="filterIndustryList"
                             @change="industryChange"></el-cascader>
              </el-form-item>
            </div>
          </div>
          <el-form-item label="" prop="amountId">
            <div class="line-el-select">
              <el-select class="el-select-width" v-model="fileFrom.record" size="small" placeholder="请选择是否录音">
                <el-option v-for="(item, index) in ['否', '是']" :key="index" :label="item" :value="index">
                </el-option>
              </el-select>

            </div>
          </el-form-item>
          <el-form-item label="">
            <el-upload class="upload-demo flex-nowrap-space-between" :headers="header" :action="uploadUrl"
                       :on-change="handleChange" :on-error="handleError" :on-success="handleSuccess"
                       :before-upload="beforerUpload" :file-list="fileList">
              <el-button size="small" class="button-confirm" icon="el-icon-upload" @click="trys">点击上传</el-button>
              <template #tip>
                <div class="errText-font">{{ errText }}</div>
              </template>
            </el-upload>
          </el-form-item>
          <el-form-item label="">
            <span class="title-font">为保证批量操作准确性，请使用模板批量上传号码...</span>
            <span class="down-font" @click="downTemplate(importType)">下载模板</span>
          </el-form-item>
        </el-form>
      </div>
      <div class="prompt-box" v-if="batchId != null">
        <div class="flex-nowrap-space-between">
          <div class="prompt-font-first">
            总计上传数量：{{ firstUp.total }}个
          </div>
          <div class="prompt-font-first">
            成功：{{ firstUp.total - firstUp.errorCount }}个
          </div>
          <div class="prompt-font-first">
            失败：{{ firstUp.errorCount }}个
          </div>
          <div class="prompt-font-first">
            重复：{{ firstUp.repeatCount }}个
          </div>
        </div>
        <div class="prompt-err-message" v-if="upErrorType == true">
          失败原因：{{ upErrorMassage }}
        </div>
      </div>
      <div class="flex-nowrap-space-around">
        <el-button size="medium" class="button-cancel" @click="quxiao">取 消</el-button>
        <!-- <el-button size="medium" class="button-confirm" v-if="firstUp.errorCount == 0&&firstUp.repeatCount==0&&importType==1" @click="upFileAllot">导入并分配</el-button>
                <el-button size="medium" class="button-confirm" v-if="firstUp.errorCount == 0&&firstUp.repeatCount==0&&importType==2" @click="upFile">导入</el-button>
                <el-button size="medium" class="button-default" v-if="firstUp.errorCount != 0||firstUp.repeatCount != 0">{{importType == 1?'导入并分配':'导入'}}</el-button> -->
        <el-button size="medium" class="button-confirm" v-if="firstUp.errorCount == 0&&importType==1"
                   @click="upFileAllot">导入并分配
        </el-button>
        <el-button size="medium" class="button-confirm" v-if="firstUp.errorCount == 0&&importType==2" @click="upFile">
          导入
        </el-button>
      </div>
    </div>
    <div v-else>
      <div class="mb-20 errTitle">失败原因:</div>
      <div class="form-out-box">
        <div v-if="errorMapData[0].errorMap">
          <el-collapse v-for="(item,index) in errorMapData" :key="index">
            <el-collapse-item :title="`用户ID:${item.uid}&nbsp;&nbsp;用户名:${item.nickname}`">
              <a-descriptions v-if="item.errorMap" bordered :column="1" size="small">
                <a-descriptions-item v-for="(items,index) in item.errorMap" :key="index"
                                     :style="{backgroundColor:'#637DFF'}">
                  <span>{{ items.interfaceName }}</span>
                  <span style="marginLeft:20px">{{ typeText(items.type) }}</span>
                </a-descriptions-item>
              </a-descriptions>
              <a-descriptions v-else><span :style="{marginLeft:'20px',color:'red'}">{{ items.error }}</span>
              </a-descriptions>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div v-else>
          <a-descriptions :style="{backgroundColor:'#637DFF'}" bordered :column="1" size="small"
                          v-for="(item,index) in errorMapData" :key="index">
            <a-descriptions-item>
              用户ID:{{ item.uid }}
              <span style="marginLeft:20px">用户名:{{ item.nickname }}</span>
              <span style="marginLeft:20px">错误原因:{{ item.error }}</span>
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import {message} from 'ant-design-vue'
import interfacets from '@/api/open/privacy/interface'
import amount from '@/api/open/amount/amount'
import number from '@/api/open/privacy/number'
import deepClone from "@/utils/deepClone";
import {toRaw} from "vue";

export default {
  name: 'bulkImport',
  props: {
    importType: {
      type: Number,
      default: '1',
    },
    industryTreeList: {
      type: Array,
      default: [],
    },
    industryList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      label: [],
      activeName: '',
      uploadUrl: '/api/system/upload/file', // 请求地址,
      header: { Authorization: Cookies.get('Authorization') }, // 请求头部
      fileList: [], //文件列表
      amountList: [], //服务商
      interface_id: [], //子账户
      fileFrom: {
        amountId: null,
        interfaceId: null,
        industryId: null,
        fileUrl: null,
        record: ''
      },
      showErrorMap: 0,
      errorMapData: [],
      upErrorType: false,
      selectErr: false,
      upErrorMassage: '',
      errText: '',
      batchId: null,
      firstUp: {
        total: null,
        repeatCount: null,
        errorCount: null,
      },
      industryValue: [],
      industryTreeProps: {
        multiple: false,
        checkStrictly: true,//Select any level of options
      },
      rules: {
        amountId: [{required: true, message: '请选择服务商', trigger: 'blur'}],
        interfaceId: [
          {required: true, message: '请选择子账户', trigger: 'blur'},
        ],
        industryId: [
          {required: true, message: '请选择行业', trigger: 'blur'},
        ],
      },
    }
  },
  mounted() {
    this.getSelectList()


    // amount.industryidlist().then((res) => {
    //   //     console.log(res.data, '99999999999999999999999999999999999========================================')
    //
    //   this.label = res.data
    //   //   let arr = []
    //   // this.getBaseTree(res.data, arr)
    //   ///  this.modeList = res.data
    //   // this.getlabel(res.data)
    // })

  },
  computed: {
    filterIndustryList() {
      //选择服务商后，只展示当下服务商行业、系统默认（amountId==0）和全行业（amountId==0）
      let res = [];
      res = this.industryTreeList?.filter(industry => {
        return industry.amountId == 0 || industry.amountId == this.fileFrom.amountId;
      })
      return res || [];
    },
  },
  methods: {
    // 根据服务商加载接口账户表
    changeValues(val) {
      interfacets
          .getInterface({
            amountId: val,
          })
          .then((res) => {
            this.interface_id = res.data
          })
    },
    industryChange(ids) {
      console.log('industryChange', ids)
      let industryId = ids ? Array.isArray(ids) ? ids[ids.length - 1] : ids : null;//单选返回是一维数组
      this.fileFrom.industryId = industryId;
    },
    trys() {
      this.$refs.fileFroms.validate((valid) => {
        console.log('trys', valid,this.fileFrom)
        if (valid) {
          this.selectErr = true
        } else {
          this.selectErr = false
          return false
        }
      })
    },
    getSelectList() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data
      })
      amount.amountQueryName({}).then((res) => {
        this.amountList = res.data
      })
    },
    //下载模板
    downTemplate(type) {
      if (type == 1) {
        window.location.href =
            'http://hiszy.oss-cn-beijing.aliyuncs.com/upload/dev/20211122/xlsx/124e262cfaa704af16a3049717b4f2ad.xlsx'
      } else {
        window.location.href =
            'https://hiszy.oss-cn-beijing.aliyuncs.com/upload/prd/templates/号码导入.xlsx?versionId=CAEQHxiBgMC8wffw4BciIGIwODhmNTc3NDNiMjQ5Mzg4Y2RlYWMwMjlhYzFiMzgx'
      }
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3)
    },
    // 文件上传前的钩子

    beforerUpload(file) {
      const isXslx =
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

      // 设置文件大小
      // const isLt2M = file.size / 1024 / 1024 < 2;
      if (this.selectErr == false) {
        this.errText = '请将条件填写完整，再进行上传！'
        this.fileList = []
        return false
      }
      if (!isXslx) {
        // message.error("请上传xslx格式文件")
        this.errText = '请上传xlsx格式文件！'
        this.fileList = []
        return false
      }
      // 限制文件大小
      // if (!isLt2M) {
      //   message.error("上传文件大小不能超过 2MB!")
      // }
      // return isLt2M && isXslx;
      this.errText = ''
      return isXslx
    },
    // 上传失败钩子
    handleError(res) {
      // message.error(res.message);
      this.upErrorMassage = res.message
      this.upErrorType = true
    },
    // 成功上传钩子
    handleSuccess(res) {
      // message.success(res.message);
      this.fileFrom.fileUrl = res.data.url
      number
          .firstImport({
            ...this.fileFrom,
          })
          .then((res) => {
            if (res.data.errorDetails != '') {
              this.upErrorType = true
              this.upErrorMassage = res.data.errorDetails
            }
            this.batchId = res.data.batchId
            this.firstUp.total = res.data.total
            this.firstUp.repeatCount = res.data.repeatCount
            this.firstUp.errorCount = res.data.errorCount
          })
    },
    // 上传
    upFile() {
      number
          .confirmImport({
            batchId: this.batchId,
          })
          .then((res) => {
            message.success('导入成功')
            this.$emit('confirmOK', 'ok')
          })
    },
    // 上传并分配
    upFileAllot() {
      number
          .confirmImport({
            batchId: this.batchId,
            fileUrl: this.fileFrom.fileUrl,
          })
          .then((res) => {
            console.log(res)
            if (!res.data || (res.data && res.data.length == 0)) {
              message.success('导入成功')
              this.$emit('confirmOK', 'ok')
            } else {
              this.errorMapData = res.data
              this.showErrorMap = 1
            }
          })
    },
    typeText(type) {
      let typeMap = {
        0: '月租',
        1: '单计费',
        2: '双计费',
      }
      return typeMap[type]
    },
    quxiao() {
      this.$emit('quxiaos', 'ok')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.ant-descriptions-item-content) {
  color: #ffffff;
}

::v-deep(.el-collapse-item__header) {
  color: #ffffff;
}

::v-deep(.el-collapse) {
  --el-collapse-header-background-color: #637dff;
}

.bulkImport {
  padding: 0 20px;
}

.el-select-width {
  width: 100%;
}

.el-select-width-other {
  width: 48%;
}

.width-28 {
  width: 28%;
}

.width-68 {
  width: 68%;
}

.title-font {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.down-font {
  font-size: 14px;
  font-weight: 400;
  color: #0058ff;
  cursor: pointer;
}

.prompt-box {
  border-top: 1px solid #e8e8e8;
  padding: 30px 0;
}

.prompt-font-first {
  font-size: 14px;
  font-weight: 700;
  color: #131523;
}

.prompt-err-message {
  margin-top: 20px;
  background: #fff0f0;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #ff1616;
}

.errText-font {
  background: #fff0f0;
  padding: 0 40px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #ff1616;
}

.button-default {
  color: #ffffff;
  background: #d7d8db;
}

.form-out-box {
  height: 400px;
  margin-bottom: 20px;
  overflow-y: overlay;
}

.errId {
  padding-left: 16px;
  color: #fff;
  height: 30px;
  line-height: 30px;
  background: #748bfd;
}

.errText {
  height: 30px;
  line-height: 30px;
  background: #f2f6fc;
}

.errTitle {
  color: #f56c6c;
}
</style>